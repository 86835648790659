import { useEffect, useState } from "react";
import { SyncedSection } from "./backend";
import { Space } from "./components/Space";
import { mockBroadcast, mockFetch, mockRefresh, mockSync } from "./mock";
import { BlockModel } from "./model/block";
import { Id } from "./model/lineage";
import { initQueue, Queue } from "./queue";

const logQueue = false;
const logBackend = false;
const useMockBackend = false;

export default function App() {
  const [queue, setQueue] = useState<Queue | null>(null);

  useEffect(() => {
    import("assemblage-view").then(assemblage => {
      console.log("Loaded Assemblage wasm module");
      assemblage.open("0").then(async (db: any) => {
        console.log("Opened AssemblageDB");
        const markupToJson = (markup: string) => {
          return JSON.parse(assemblage.markup_to_json(markup));
        };
        const jsonToMarkup = (block: BlockModel) => {
          return assemblage.json_to_markup(JSON.stringify(block));
        };
        const refreshFromDB = async (store: string | null, id: Id) => {
          if (logBackend) console.log(`--> ${store}/${id}`);
          const result = await db.refresh(store, id);
          if (logBackend) console.log("<--");
          if (logBackend) console.log(JSON.stringify(result, null, 2));
          return result;
        };
        const syncWithDB = async (id: Id | null, tile: readonly SyncedSection[]) => {
          if (logBackend) console.log(`==> ${id}`);
          if (logBackend) console.log(JSON.stringify(tile, null, 2));
          const result = await db.sync(id, tile);
          if (logBackend) console.log("<==");
          if (logBackend) console.log(JSON.stringify(result, null, 2));
          return result;
        };
        const broadcastFromDB = async (id: Id) => {
          if (logBackend) console.log(`~~> ${id}`);
          const result = await db.broadcast(id);
          if (logBackend) console.log("<~~");
          if (logBackend) console.log(JSON.stringify(result, null, 2));
          return result;
        }
        const fetchFromDB = async (store: string, id: Id) => {
          if (logBackend) console.log(`::> ${store}/${id}`);
          const result = await db.fetch(store, id);
          if (logBackend) console.log("<::");
          if (logBackend) console.log(JSON.stringify(result, null, 2));
          return result;
        }
        const refresh = useMockBackend ? mockRefresh : refreshFromDB;
        const sync = useMockBackend ? mockSync : syncWithDB;
        const broadcast = useMockBackend ? mockBroadcast : broadcastFromDB;
        const fetch = useMockBackend ? mockFetch : fetchFromDB;
        const backend = {
          grove: 0,
          markupToJson,
          jsonToMarkup,
          refresh,
          sync,
          broadcast,
          fetch,
        };
        setQueue(initQueue(backend, logQueue));
      });
    });
  }, []);

  if (queue === null) {
    return <div></div>;
  } else {
    return <Space queue={queue} />;
  }
}
