import { isEditable, SectionModel } from "./section";
import { Id } from "./lineage";
import { BlockModel } from "./block";
import { Branch } from "./branch";
import { BroadcastModel } from "./broadcast";

export type TileModel = NewTile | PersistentTile;

export interface NewTile {
    readonly store: string | null;
    readonly id: Id | null;
    readonly preview?: undefined;
    readonly broadcasts: readonly BroadcastModel[];
    readonly sections: undefined;
    readonly branches: readonly Branch[];
    readonly activeSection?: number;
    readonly activeBlock?: number;
}

export interface PersistentTile {
    readonly store: string | null;
    readonly id: Id | null;
    readonly preview: BlockModel;
    readonly broadcasts: readonly BroadcastModel[];
    readonly sections: readonly SectionModel[];
    readonly activeSection?: number;
    readonly activeBlock?: number;
    readonly caret?: Caret;
    readonly branches: readonly Branch[];
}

export function hashToTile(hash: string): NewTile {
    if (hash === "new") return {
        store: null,
        id: null,
        broadcasts: [],
        sections: undefined,
        branches: [],
    };
    const parsed = tryParseHash(hash);
    if (parsed === null) {
        throw new Error(`Invalid hash: ${hash}`);
    } else {
        return parsed;
    }
}

export function tileToHash(t: TileModel): string {
    if (t.id === null) {
        return "new";
    } else {
        const idAsUrlHash = `${t.store || "0"}/${t.id}`;
        if (t.sections === undefined || t.activeSection === undefined) {
            return idAsUrlHash
        } else {
            const fragment = t.activeBlock !== undefined ?
                `${t.activeSection},${t.activeBlock}` :
                t.activeSection.toString();
            return `${idAsUrlHash},${fragment}`;
        }
    }
}

function tryParseHash(hash: string): NewTile | null {
    const idSeparator = "/";
    const addressSeparator = ',';

    const [storeAndId, ...fragment] = hash.split(addressSeparator);
    const [store, id] = storeAndId.split(idSeparator);

    const activeSection = fragment.length > 0 ? parseInt(fragment[0]) : undefined;
    if (activeSection !== undefined && isNaN(activeSection)) return null;
    const activeBlock = fragment.length > 1 ? parseInt(fragment[1]) : undefined;
    if (activeBlock !== undefined && isNaN(activeBlock)) return null;
    return {
        store,
        id,
        broadcasts: [],
        sections: undefined,
        branches: [],
        activeSection,
        activeBlock,
    };
}

export function needSync(t1: PersistentTile, t2: PersistentTile): boolean {
    return !sameSections(t1, t2);
}

function sameSections(t1: PersistentTile, t2: PersistentTile): boolean {
    if (t1.sections.length !== t2.sections.length) return false;
    for (var i = 0; i < t1.sections.length; ++i) {
        if (t1.sections[i] !== t2.sections[i]) return false;
    }
    return true;
}

export function isExternal(tile: TileModel): boolean {
    return tile.store !== null && tile.store !== "0";
}

export function move(tile: PersistentTile, from: number, to: number): PersistentTile {
    const section = tile.sections[from];
    const moved = to > from ?
        remove(insert(tile, to, section), from) :
        insert(remove(tile, from), to, section);
    return tile.activeSection === from ? { ...moved, activeSection: to } : moved;
}

export function insert(tile: PersistentTile, index: number, section: SectionModel): PersistentTile {
    return ({
        ...tile,
        sections: [
            ...tile.sections.slice(0, index),
            section,
            ...tile.sections.slice(index)
        ],
        activeSection: tile.activeSection !== undefined && tile.activeSection >= index ?
            tile.activeSection + 1 : tile.activeSection,
    });
}

export function replace(tile: PersistentTile, index: number, section: SectionModel): PersistentTile {
    return ({
        ...tile,
        sections: [
            ...tile.sections.slice(0, index),
            section,
            ...tile.sections.slice(index + 1)
        ],
        activeBlock: tile.activeSection === index &&
            tile.activeBlock !== undefined &&
            tile.activeBlock >= section.subsections.length ?
            undefined : tile.activeBlock,
    });
}

export function remove(tile: PersistentTile, index: number): PersistentTile {
    const activeSectionRemoved = tile.activeSection !== undefined && tile.activeSection === index;
    return ({
        ...tile,
        sections: [
            ...tile.sections.slice(0, index),
            ...tile.sections.slice(index + 1)
        ],
        activeSection: activeSectionRemoved ?
            undefined : (tile.activeSection !== undefined && tile.activeSection > index) ?
                tile.activeSection - 1 : tile.activeSection,
        activeBlock: activeSectionRemoved ? undefined : tile.activeBlock,
    });
}

type BlockSelection = { section?: number, block?: number };

export function selectBlock(tile: PersistentTile, selection: BlockSelection): PersistentTile {
    return { ...tile, activeSection: selection.section, activeBlock: selection.block };
}

export function atCaretPosition(tile: PersistentTile, caret: Caret): PersistentTile {
    return { ...tile, caret };
}

export type Caret = AbsoluteOffsetCaret | EndOfTextCaret | RelativeToFirstLineCaret | RelativeToLastLineCaret;

interface AbsoluteOffsetCaret {
    readonly type: "start";
    readonly offset?: number;
}

interface EndOfTextCaret {
    readonly type: "end";
    readonly offset?: number;
}

interface RelativeToFirstLineCaret {
    readonly type: "first";
    readonly offset: number;
}

interface RelativeToLastLineCaret {
    readonly type: "last";
    readonly offset: number;
}

export function navigate(tile: PersistentTile, s: number, b: number | undefined, caret: Caret): PersistentTile {
    const direction = (caret.type === "start" || caret.type === "first") ? "down" : "up";
    const sectionAndBlock = siblingBlock(tile, s, b, direction);
    if (sectionAndBlock !== null) {
        const { section, block } = sectionAndBlock;
        const selected = selectBlock(tile, { section, block });
        return atCaretPosition(selected, caret);
    } else {
        const section = siblingSection(tile, s, direction);
        if (section !== null && !isEditable(isExternal(tile), tile.sections[section])) {
            return selectBlock(tile, { section });
        } else {
            return tile;
        }
    }
}

function siblingBlock(tile: PersistentTile, s: number, b: number | undefined, direction: "up" | "down"): BlockSelection | null {
    const step = direction === "up" ? -1 : 1;
    const subsections = tile.sections[s].subsections;
    if (b === undefined) {
        b = direction === "up" ? 0 : subsections.length - 1;
    }
    if (b + step >= 0 && b + step < subsections.length) {
        return { section: s, block: b + step };
    } else {
        while (s + step >= 0 && s + step < tile.sections.length) {
            s += step;
            if (!isEditable(isExternal(tile), tile.sections[s])) {
                return null;
            }
            const subsections = tile.sections[s].subsections;
            if (subsections.length > 0) {
                return ({
                    section: s,
                    block: direction === "up" ? subsections.length - 1 : 0
                });
            }
        }
        return null;
    }
}

function siblingSection(tile: PersistentTile, s: number, direction: "up" | "down"): number | null {
    const step = direction === "up" ? -1 : 1;
    const i = s + step;
    return i < 0 || i >= tile.sections.length ? null : i;
}
