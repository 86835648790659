import React from "react";
import { BlockModel } from "../model/block";
import { SectionModel } from "../model/section";
import { SubsectionModel } from "../model/subsection";
import { Caret } from "../model/tile";
import { Block } from "./Block";
import { Branches } from "./Branches";
import { LinkableProps, MarkupProps } from "./Space";

interface Props extends LinkableProps, MarkupProps {
    readonly isActive: boolean;
    readonly isEditable: boolean;
    readonly blockIndex: number;
    readonly subsection: SubsectionModel;
    readonly caret: Caret | null;
    readonly select: () => void;
    readonly unselect: (updated: SectionModel[] | null) => void;
    readonly updateContent: (sections: SectionModel[], caret: number) => void;
    readonly mergeWith: (target: "prev" | "next", block: BlockModel, blockLength: number) => void;
}

export const Subsection: React.FC<Props> = ({
    blockIndex,
    subsection,
    ...rest
}) => {
    const isFirstBlock = rest.sectionIndex === 0 && blockIndex === 0;
    return (
        <>
            <Branches
                links={subsection.before}
                position="before"
                {...rest}
            />
            <Block
                isFirstBlock={isFirstBlock}
                block={subsection.block}
                {...rest}
            />
            <Branches
                links={subsection.after}
                position="after"
                {...rest}
            />
        </>
    );
}
