import { Style } from "./block";
import { Id } from "./lineage";
import { SubsectionModel } from "./subsection";
import { isEditable as isBlockEditable } from "./block";
import { Keyed } from "./keyed";

export type SectionModel = SectionWithoutIdModel | SectionWithIdModel | LinkedSectionModel;

export interface SectionWithoutIdModel extends Keyed {
    readonly id: null;
    readonly linked?: undefined;
    readonly hasMultipleParents: boolean;
    readonly styles?: Set<Style>;
    readonly subsections: readonly SubsectionModel[];
}

export interface SectionWithIdModel extends Keyed {
    readonly id: Id;
    readonly linked?: undefined;
    readonly hasMultipleParents: boolean;
    readonly styles?: Set<Style>;
    readonly subsections: readonly SubsectionModel[];
}

export interface LinkedSectionModel extends Keyed {
    readonly id: null;
    readonly linked: Id;
    readonly hasMultipleParents: true;
    readonly styles?: Set<Style>;
    readonly subsections: readonly SubsectionModel[];
}

export function isEditable(isExternal: boolean, s: SectionModel): boolean {
    return !isExternal && !s.hasMultipleParents && !s.subsections.some(s => !isBlockEditable(s.block));
}
