import { Queue } from "../queue";
import { rootId } from "./lineage";
import { PersistentTile, hashToTile, tileToHash, TileModel } from "./tile";

export interface SpaceModel {
    readonly start: number;
    readonly queue: Queue;
    readonly keys: readonly number[];
    readonly timestamps: Map<number, number>;
    readonly tiles: Map<number, TileModel>;
    readonly sync: Map<number, PersistentTile>;
}

export function hashToSpace(queue: Queue, hash: string): SpaceModel {
    const empty: SpaceModel = {
        start: now(),
        queue,
        keys: [],
        timestamps: new Map(),
        tiles: new Map(),
        sync: new Map(),
    };
    const tiles: readonly TileModel[] = hash === ""
        ? [{ store: null, id: rootId, broadcasts: [], branches: [], sections: undefined }]
        : hash.substring(1).split("&").map(hashToTile);
    return tiles.reduce((space, tile) => addTile(space, tile, now()), empty);
}

export function spaceToHash(space: SpaceModel): string {
    return `#${space.keys.map(k => {
        return tileToHash(getTile(space, k));
    }).join("&")}`;
}

export function now(): number {
    return window.performance.now();
}

export function getTile(s: SpaceModel, k: number): TileModel {
    return s.tiles.get(k) as TileModel;
}

export function addTileNow(s: SpaceModel, tile: TileModel): SpaceModel {
    return addTile(s, tile, now());
}

export function addTile(s: SpaceModel, tile: TileModel, timestamp: number): SpaceModel {
    const { start, keys, timestamps, tiles, sync } = s;
    const k = Math.random();
    timestamp -= start;
    return {
        ...s,
        keys: [...keys, k],
        timestamps: new Map([...Array.from(timestamps.entries()), [k, timestamp]]),
        tiles: new Map([...Array.from(tiles.entries()), [k, tile]]),
        sync,
    }
}

export function replaceTileNow(s: SpaceModel, k: number, tile: TileModel): SpaceModel {
    return replaceTile(s, k, tile, now());
}

export function replaceTile(s: SpaceModel, k: number, tile: TileModel, timestamp: number): SpaceModel {
    const { start, timestamps, tiles } = s;
    timestamp -= start;
    if ((timestamps.get(k) ?? 0) > timestamp) {
        return s;
    }
    return ({
        ...s,
        timestamps: new Map([...Array.from(timestamps.entries()), [k, timestamp]]),
        tiles: new Map([...Array.from(tiles.entries()), [k, tile]]),
    });
}

export function refreshTile(s: SpaceModel, k: number, tile: PersistentTile, timestamp: number): SpaceModel {
    const { start, timestamps, tiles, sync } = s;
    timestamp -= start;
    if ((timestamps.get(k) ?? 0) > timestamp) {
        return s;
    }
    return ({
        ...s,
        timestamps: new Map([...Array.from(timestamps.entries()), [k, timestamp]]),
        tiles: new Map([...Array.from(tiles.entries()), [k, tile]]),
        sync: new Map([...Array.from(sync.entries()), [k, tile]]),
    });
}

export function mergeSyncedTile(s: SpaceModel, k: number, synced: PersistentTile, timestamp: number): SpaceModel {
    const { start, timestamps, tiles, sync } = s;
    timestamp -= start;
    if ((timestamps.get(k) ?? 0) > timestamp) {
        return s;
    }
    const edited = getTile(s, k);
    const merged = edited.sections !== undefined && edited.activeSection !== undefined ?
        {
            ...synced,
            activeSection: edited.activeSection,
            activeBlock: edited.activeBlock,
            caret: edited.caret,
        } :
        synced;
    return ({
        ...s,
        timestamps: new Map([...Array.from(timestamps.entries()), [k, timestamp]]),
        tiles: new Map([...Array.from(tiles.entries()), [k, merged]]),
        sync: new Map([...Array.from(sync.entries()), [k, merged]]),
    });
}

export function removeTile(s: SpaceModel, k: number): SpaceModel {
    const { keys, timestamps, tiles, sync } = s;
    return ({
        ...s,
        keys: Array.from(keys.filter(key => key !== k)),
        timestamps: new Map([...Array.from(timestamps.entries())].filter(([key, v]) => key !== k)),
        tiles: new Map([...Array.from(tiles.entries())].filter(([key, v]) => key !== k)),
        sync: new Map([...Array.from(sync.entries())].filter(([key, v]) => key !== k)),
    });
}
