import { ReactElement } from "react";
import { LinkableProps } from "./Space";
import { SpanModel, Style } from "../model/span";
import { Style as BlockStyle } from "../model/block";
import { LineageLink } from "./Link";

interface Props extends LinkableProps {
    readonly span: SpanModel;
    readonly classForMarkup?: string;
}

export const Span: React.FC<Props> = ({ span, classForMarkup, ...rest }) => {
    if (span.type === "Text") {
        return fold(<>{span.text}</>, new Set(span.styles));
    } else if (span.type === "Link") {
        const l = <LineageLink link={span.link} withAncestors={false} position={"after"} {...rest} />;
        return fold(l, new Set(span.styles));
    } else {
        const unreachable: never = span;
        throw new Error(`Invalid span: ${unreachable}`);
    }
}

export function fold(child: ReactElement, styles: Set<Style | BlockStyle> | undefined): ReactElement {
    return Array.from(styles || new Set([])).reduce(styled, child);
}

function styled(child: ReactElement, style: Style | BlockStyle): ReactElement {
    if (style === "Bold") {
        return <strong>{child}</strong>;
    } else if (style === "Italic") {
        return <em>{child}</em>;
    } else if (style === "Struck") {
        return <s>{child}</s>;
    } else if (style === "Mono") {
        return <code>{child}</code>;
    } else if (style === "Marked") {
        return <mark>{child}</mark>;
    } else {
        return child;
    }
}
