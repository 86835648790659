export interface Keyed {
    key?: number;
}

export function keyed(obj: Keyed): number {
    if (obj.key === undefined) {
        obj.key = Math.random();
    }
    return obj.key;
}
