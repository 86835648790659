import { useCallback, useEffect, useState } from "react";
import { BroadcastModel } from "../model/broadcast";
import { hashId, Id } from "../model/lineage";

export interface BroadcastActionProps {
    readonly id: Id;
    readonly alreadyActive: boolean;
    readonly startBroadcast: (id: Id, onComplete: () => void) => void;
}

export const BroadcastAction: React.FC<BroadcastActionProps> = ({ id, alreadyActive, startBroadcast }) => {
    const [isBusy, setBusy] = useState(false);
    const startBroadcastWithVisualFeedback = useCallback(() => {
        setBusy(true);
        startBroadcast(id, () => setBusy(false));
    }, [startBroadcast, id]);
    return (
        <button
            disabled={isBusy || alreadyActive}
            className={`start-broadcast assemblage-logo ${isBusy ? "busy" : ""} ${alreadyActive ? "active" : ""}`}
            onClick={startBroadcastWithVisualFeedback}>
            <img src="assemblage_broadcast.svg" alt="Assemblage Broadcast Icon"></img>
        </button>
    );
}

export interface BroadcastProps {
    model: BroadcastModel
}

export const Broadcast: React.FC<BroadcastProps> = ({ model }) => {
    const { broadcast_id, node_id, expiration } = model;
    const [currentTime, setCurrentTime] = useState(Date.now());

    useEffect(() => {
        const interval = setInterval(() => {
            setCurrentTime(Date.now());
        }, 1);
        return function cleanup() {
            clearInterval(interval);
        };
    }, []);

    const baseUrl = window.location.origin + window.location.pathname;
    const url = `${baseUrl}#${hashId(broadcast_id, node_id)}`;
    const copyToClipboard = useCallback((e) => {
        navigator.clipboard.writeText(url);
        e.target.select();
    }, [url]);

    var countdown = "";
    if (expiration !== null) {
        const timeLeft = expiration - currentTime;
        if (timeLeft < 0) {
            return (
                <div>
                    <input disabled readOnly type="text" value={url} />
                </div>
            );
        }
        const h = Math.floor(timeLeft / 1000 / 60 / 60);
        const m = Math.floor((timeLeft / 1000 / 60) % 60);
        const s = Math.floor((timeLeft / 1000) % 60);
        countdown = ` (${h}:${m < 10 ? "0" : ""}${m}:${s < 10 ? "0" : ""}${s})`;
    }
    const link = <input readOnly type="text" value={url} onClick={copyToClipboard} />;
    return <div>{link}{countdown}</div>;
}