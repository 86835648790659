import { BlockModel } from "./block";

export interface Lineage {
    readonly descendant: PreviewLink;
    readonly ancestor?: PreviewLink;
    readonly descent: readonly Parent[];
}

export interface PreviewLink {
    readonly id: Id;
    readonly block: BlockModel;
}

export type Id = string;

export const rootId = "0";

export interface Parent {
    readonly id: Id,
    readonly index: number,
}

export function hashLink(store: string | null, link: Lineage): string {
    const hashedId = hashId(store, link.descendant.id);
    if (link.ancestor === undefined) {
        return hashedId;
    } else {
        const descent = link.descent.map(p => p.index).join(":");
        return `${hashedId}:${descent}`;
    }
}

export function hashId(store: string | null, id: Id): string {
    return `${store ?? "0"}/${id}`;
}
