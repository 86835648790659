import { isEditable, SectionModel } from "../model/section";
import { DragAwareProps, LinkableProps, MarkupProps } from "./Space";
import { SectionLink } from "./Link";
import { Subsection } from "./Subsection";
import { useCallback, useEffect, useRef } from "react";
import { Caret } from "../model/tile";
import { BlockModel, isEmpty } from "../model/block";
import { keyed } from "../model/keyed";

interface Props extends LinkableProps, MarkupProps, DragAwareProps {
    readonly section: SectionModel;
    readonly isExternal: boolean;
    readonly isSelected: boolean;
    readonly isLastSection: boolean;
    readonly activeBlock?: number;
    readonly caret: Caret | null;
    readonly selectBlock: (block: number | undefined) => void;
    readonly unselect: (block: number | undefined, updated: SectionModel[] | null) => void;
    readonly updateContent: (block: number, sections: SectionModel[], caret: number) => void;
    readonly mergeWith: (block: number, target: "prev" | "next", content: BlockModel, contentLength: number) => void;
    readonly remove: () => void;
    readonly add: () => void;
}

export const Section: React.FC<Props> = ({
    section,
    dragState,
    isExternal,
    isSelected,
    isLastSection,
    activeBlock,
    caret,
    selectBlock,
    unselect,
    updateContent,
    mergeWith,
    remove,
    add,
    ...rest
}) => {
    const isHighlighted = !isEditable(isExternal, section) || dragState.isDragActive;
    const ref = useRef<HTMLElement>(null);

    useEffect(() => {
        if (ref.current !== null && isSelected) {
            ref.current.focus();
        }
    }, [isSelected]);

    const onClick = useCallback((e: React.MouseEvent) => {
        e.preventDefault();
        e.stopPropagation();
        selectBlock(undefined);
    }, [selectBlock]);

    const onBlur = useCallback((e: React.MouseEvent) => {
        e.preventDefault();
        e.stopPropagation();
        unselect(undefined, null);
    }, [unselect]);

    const onKeyDown = useCallback((e: React.KeyboardEvent) => {
        if (e.key === "Escape") {
            e.preventDefault();
            e.stopPropagation();
            unselect(undefined, null);
        } else if (e.key === "Enter" && !isExternal) {
            e.preventDefault();
            e.stopPropagation();
            add();
        } else if (e.key === "Backspace" && !isExternal) {
            e.preventDefault();
            e.stopPropagation();
            remove();
        }
    }, [isExternal, unselect, remove, add]);

    let attrs = {};
    if (isHighlighted) {
        attrs = { ...attrs, onKeyDown, onClick };
    }
    if (isHighlighted && isSelected) {
        attrs = { ...attrs, onBlur, tabIndex: 0 };
    }
    return (
        <article {...isSelected && { className: "selected" }}>
            <section ref={ref} {...attrs}>
                {section.subsections.map((s, i) => {
                    const reversedIdx = (i - section.subsections.length);
                    const isActive = isSelected && (activeBlock === i || activeBlock === reversedIdx);
                    const c = !isActive ? null :
                        activeBlock === reversedIdx ?
                            { type: "end" as const } :
                            caret;
                    return (
                        <Subsection
                            key={keyed(s)}
                            isActive={isActive}
                            isEditable={isEditable(isExternal, section)}
                            caret={c}
                            blockIndex={i}
                            subsection={s}
                            select={() => selectBlock(i)}
                            unselect={(updated) => unselect(i, updated)}
                            updateContent={(sections: SectionModel[], caret) => updateContent(i, sections, caret)}
                            mergeWith={(target, block, blockLength) => mergeWith(i, target, block, blockLength)}
                            {...rest}
                        />
                    );
                })}
            </section>
            <SectionLink
                section={section}
                isHighlighted={isHighlighted}
                isSectionEmpty={isLastSection && !section.subsections.some(s => !isEmpty(s.block))}
                {...rest}
            />
        </article>
    );
}
