import { blockName } from "../model/block";
import { hashId, hashLink, Lineage, rootId } from "../model/lineage";
import { SectionModel, SectionWithIdModel } from "../model/section";
import { TileModel } from "../model/tile";
import { LinkableProps } from "./Space";

interface LineageLinkProps extends LinkableProps {
    readonly link: Lineage;
    readonly withAncestors: boolean;
    readonly position: "before" | "after";
}

export const LineageLink: React.FC<LineageLinkProps> = ({ uri, link, withAncestors, position, sectionIndex, onOpenTile, onDragLink }) => {
    link = link.ancestor?.id === undefined || !withAncestors ?
        { descendant: link.descendant, descent: [] } :
        link;
    const id = link.ancestor?.id ?? link.descendant.id;
    const activeSection = link.descent.length > 0 ? link.descent[0].index : undefined;
    const activeBlock = link.descent.length > 0 ? (position === "before" ? -1 : 0) : undefined;
    const ancestorBlockName = link.ancestor === undefined ? "" : blockName(link.ancestor.block);
    const descendantBlockName = blockName(link.descendant.block);
    return (
        <a
            href={`#${hashLink(uri, link)}`}
            onClick={(e) => onOpenTile(e, uri, id, activeSection, activeBlock)}
            onDragStart={(e: React.DragEvent) => onDragLink(e, uri, sectionIndex, link)}
        >
            {ancestorBlockName !== "" && withAncestors &&
                <span className="ancestor-title">{ancestorBlockName} — </span>}
            <span>{descendantBlockName}</span>
        </a>
    );
}

interface SectionLinkProps extends LinkableProps {
    readonly section: SectionModel;
    readonly isHighlighted: boolean;
    readonly isSectionEmpty: boolean;
}

export const SectionLink: React.FC<SectionLinkProps> = ({
    uri,
    section,
    sectionIndex,
    isHighlighted,
    isSectionEmpty,
    onOpenTile,
    onDragSection
}) => {
    const id = section.id;
    if (id === null) {
        return <span className="section-without-id" />;
    } else {
        return (
            <a
                href={`#${hashId(uri, id)}`}
                className={isHighlighted ? "highlighted" : isSectionEmpty ? "empty" : ""}
                onClick={(e) => onOpenTile(e, uri, id, undefined, undefined)}
                onDragStart={(e: React.DragEvent) => onDragSection(e, uri, sectionIndex, section as SectionWithIdModel)}
            >
                <span>View Section</span>
            </a>
        );
    }
}

export interface RootLinkProps extends LinkableProps {
    readonly tile: TileModel;
}

export const RootLink: React.FC<RootLinkProps> = ({ uri, tile, onOpenTile, onDragTile }) => {
    return (
        <a
            className="assemblage-logo"
            href={`#${hashId(tile.store, rootId)}`}
            onClick={(e) => onOpenTile(e, tile.store, rootId, undefined, undefined)}
            onDragStart={(e: React.DragEvent) => onDragTile(e, uri, tile)}
        >
            <img src="assemblage_logo_solid_yellow.svg" alt="Assemblage Logo"></img>
        </a>
    )
}
