import { Backend, SyncedSection } from "./backend";
import { hashId, Id } from "./model/lineage";
import { PersistentTile } from "./model/tile";

type Consumer = (synced: PersistentTile) => Promise<void>;

export interface Queue {
    readonly backend: Backend;
    readonly log: boolean;
    counter: number;
    running: Set<String | null>;
    waiting: Map<String | null, () => Promise<PersistentTile>>;
    consumers: Map<String | null, Consumer[]>;
}

export function initQueue(backend: Backend, log: boolean): Queue {
    return {
        backend,
        log,
        counter: 0,
        running: new Set(),
        waiting: new Map(),
        consumers: new Map(),
    };
}

export function scheduleSync(q: Queue, id: Id | null, tile: readonly SyncedSection[]): void {
    const job = () => q.backend.sync(id, tile);
    const hashed = id === null ? "" : hashId(null, id);
    schedule(q, job, hashed);
}

export function scheduleBroadcast(q: Queue, id: Id): void {
    const job = () => q.backend.broadcast(id);
    const hashed = id === null ? "" : hashId(null, id);
    schedule(q, job, hashed);
}

export function awaitSync(q: Queue, store: string | null, id: Id | null, consumer: Consumer): void {
    const hashed = id === null ? "" : hashId(store, id);
    const consumers = q.consumers.get(hashed);
    if (consumers !== undefined) {
        consumers.push(consumer);
    } else {
        const consumers: Consumer[] = [consumer];
        q.consumers.set(hashed, consumers);
    }
}

function schedule(q: Queue, job: () => Promise<PersistentTile>, hashed: string): void {
    if (!q.running.has(hashed)) {
        if (q.log) console.log("Queue is empty, running job immediately");
        enqueue(q, hashed, job());
        q.running.add(hashed);
    } else {
        if (q.log) console.log("Another job is still running, waiting for it to finish");
        q.waiting.set(hashed, job);
    }
}

function enqueue(q: Queue, id: string, p: Promise<PersistentTile>): Promise<void> {
    return p.then(async tile => {
        const waiting = q.waiting.get(id);
        q.waiting.delete(id);
        if (waiting !== undefined) {
            if (q.log) console.log("Starting next waiting job");
            enqueue(q, id, waiting());
        } else {
            q.running.delete(id);
            if (q.log) console.log("No more jobs to run");
            for (let consume of q.consumers.get(id) ?? []) {
                await consume(tile);
            }
            q.consumers.delete(id);
        }
    }).catch(error => {
        q.running.delete(id);
        console.error(error);
        throw error;
    });
}
