import { SpanModel, spanName, Style as SpanStyle, isEmpty as isSpanEmpty } from "./span";

export type BlockModel = TextBlockModel;

export interface TextBlockModel {
    readonly type: "Text";
    readonly styles?: readonly Style[];
    readonly spans: readonly SpanModel[];
}

export type Style = "Aside" | "Quote" | "List" | "Heading" | SpanStyle;

export function blockName(block: BlockModel): string {
    return block.spans.map(spanName).join("");
}

export function isEditable(block: BlockModel): boolean {
    return !block.spans.some(s => s.type === "Link");
}

export function isEmpty(block: BlockModel): boolean {
    return block.spans.length === 0 || !block.spans.some(s => !isSpanEmpty(s));
}
