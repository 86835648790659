import { LinkableProps, MarkupProps } from "./Space";
import { SectionLink } from "./Link";

export interface Props extends LinkableProps, MarkupProps {
    readonly onClick: () => void;
}

function examples(examples: (string | JSX.Element)[][], markers: "single" | "double"): React.ReactElement[][] {
    return examples.map(([m, text, element]) => {
        return (
            [
                markers === "single" ?
                    <span className="markup-visible" key={`${m}-0`}>
                        <span className="markup-example-marker">{m}</span>
                        <span>&nbsp;&nbsp;{text}</span>
                    </span> :
                    <span className="markup-visible" key={`${m}-0`}>
                        <span className="markup-example-marker">{m}</span>
                        {text}
                        <span className="markup-example-marker">{m}</span>
                    </span>,
                <span key={`${m}-1`} className={"markup-example-separator"}>→</span>,
                <span key={`${m}-2`} className="markup-example">{element}</span>,
                <br key={`${m}-3`} />,
            ]
        );
    });
}

export const PlaceholderSection: React.FC<Props> = ({ onClick, ...props }) => {
    const blockExamples = [
        ["#", "Heading", <h2>Heading</h2>],
        [">", "Quote", <blockquote><p>Quote</p></blockquote>],
        ["-", "Bullet", <ul className={"bullet"}><li>Bullet</li></ul>],
        [",", "Aside", <aside>Aside</aside>]
    ];
    const spanExamples = [
        ["*", "bold", <strong>bold</strong>],
        ["_", "italic", <em>italic</em>],
        ["~", "struck", <s>struck</s>],
        ["`", "mono", <code>mono</code>],
        ["|", "marked", <mark>marked</mark>],
    ];
    return (
        <article className="placeholder" onClick={onClick}>
            <section>
                <div className={"markup-examples"}>
                    {examples(blockExamples, "single")}
                    <br />
                    {examples(spanExamples, "double")}
                </div>
            </section>
            <SectionLink
                section={{ id: null, hasMultipleParents: false, subsections: [] }}
                isHighlighted={false}
                isSectionEmpty={true}
                {...props}
            />
        </article>
    );
}
