import { Backend, SyncedSection } from "./backend";
import { BlockModel } from "./model/block";
import { Id, Parent, rootId } from "./model/lineage";
import { PersistentTile } from "./model/tile";

export const mockBackend: Backend = {
    markupToJson: mockMarkupToJson,
    jsonToMarkup: mockJsonToMarkup,
    refresh: mockRefresh,
    sync: mockSync,
    broadcast: mockBroadcast,
    fetch: mockFetch,
};

export function mockMarkupToJson(markup: string): BlockModel {
    return ({
        type: "Text" as const,
        spans: [
            {
                type: "Text" as const,
                text: markup,
            }
        ],
    });
}

export function mockJsonToMarkup(block: BlockModel): string {
    return (block.spans.map(s => {
        return s.type === "Text" ? s.text : "";
    }).join(""));
}

export async function mockRefresh(store: string | null, id: Id): Promise<PersistentTile> {
    if (id === "0") {
        return tile1;
    } else if (id === "1") {
        return tile2;
    } else if (id === "2") {
        return tile3;
    }
    throw new Error(`Invalid id: ${id}`);
}

export async function mockSync(id: Id | null, sections: readonly SyncedSection[]): Promise<PersistentTile> {
    if (id === null) {
        return tile1;
    } else if (id === "0") {
        return tile1;
    } else if (id === "1") {
        return tile2;
    } else if (id === "2") {
        return tile3;
    }
    throw new Error(`Invalid id: ${id}`);
}

export async function mockBroadcast(id: Id): Promise<PersistentTile> {
    if (id === null) {
        return tile1;
    } else if (id === "0") {
        return tile1;
    } else if (id === "1") {
        return tile2;
    } else if (id === "2") {
        return tile3;
    }
    throw new Error(`Invalid id: ${id}`);
}

export async function mockFetch(store: string, id: Id): Promise<PersistentTile> {
    if (id === null) {
        return tile1;
    } else if (id === "0") {
        return tile1;
    } else if (id === "1") {
        return tile2;
    } else if (id === "2") {
        return tile3;
    }
    throw new Error(`Invalid id: ${id}`);
}

function id(address: number): Id {
    return address.toString(16);
    /*return ({
        address: address,
    });*/
}

function parent({ address, index }: { address: number, index: number }): Parent {
    return ({
        id: address.toString(16),
        index: index,
    })
}

const tile1 = {
    store: null,
    id: rootId,
    broadcasts: [],
    preview: {
        type: "Text" as const,
        styles: ["Heading" as const],
        spans: [
            {
                type: "Text" as const,
                text: "Title"
            }
        ],
    },
    branches: [],
    sections: [
        {
            id: id(1),
            hasMultipleParents: false,
            subsections: [],
        },
        {
            id: id(1),
            hasMultipleParents: false,
            subsections: [
                {
                    id: id(1),
                    block: {
                        type: "Text" as const,
                        spans: [],
                    }
                }
            ]
        },
        {
            id: id(1),
            hasMultipleParents: false,
            subsections: [
                {
                    id: id(1),
                    block: {
                        type: "Text" as const,
                        styles: ["Heading" as const],
                        spans: [
                            {
                                type: "Text" as const,
                                text: "Title"
                            }
                        ],
                    },
                },
                {
                    id: id(1),
                    block: {
                        type: "Text" as const,
                        styles: ["Heading" as const],
                        spans: [
                            {
                                type: "Text" as const,
                                text: "Heading"
                            }
                        ],
                    },
                    before: [
                        {
                            type: "Sibling" as const,
                            link: {
                                descendant: {
                                    id: id(1),
                                    block: {
                                        type: "Text" as const,
                                        spans: [
                                            {
                                                type: "Text" as const,
                                                text: "Home",
                                            }
                                        ],
                                    }
                                },
                                descent: [
                                    parent({ address: 9, index: 1 }),
                                    parent({ address: 10, index: 0 }),
                                ]
                            }
                        }
                    ],
                },
                {
                    id: id(1),
                    block: {
                        type: "Text" as const,
                        styles: ["Quote" as const, "Italic" as const],
                        spans: [
                            {
                                type: "Text" as const,
                                text: "What a memorable italic quote! This should be long enough that it stretches over two lines, hopefully, so that I can test up & down navigation keys.",
                            }
                        ]
                    }
                },
                {
                    id: id(1),
                    block: {
                        type: "Text" as const,
                        styles: ["Aside" as const],
                        spans: [
                            {
                                type: "Text" as const,
                                text: "Just an aside...",
                            }
                        ]
                    }
                },
                {
                    id: id(1),
                    block: {
                        type: "Text" as const,
                        styles: ["List" as const],
                        spans: [
                            {
                                type: "Text" as const,
                                text: "Point 1",
                            }
                        ]
                    }
                },
                {
                    id: id(1),
                    block: {
                        type: "Text" as const,
                        styles: ["List" as const],
                        spans: [
                            {
                                type: "Text" as const,
                                text: "Point 2",
                            }
                        ]
                    }
                },
            ]
        },
        {
            id: id(2),
            hasMultipleParents: false,
            subsections: [
                {
                    id: id(2),
                    block: {
                        type: "Text" as const,
                        spans: [
                            {
                                type: "Text" as const,
                                text: "Another section",
                            }
                        ]
                    }
                },
                {
                    id: id(1),
                    block: {
                        type: "Text" as const,
                        spans: [],
                    }
                }
            ]
        },
        {
            id: id(1),
            hasMultipleParents: false,
            subsections: [
                {
                    id: id(1),
                    block: {
                        type: "Text" as const,
                        spans: [],
                    }
                }
            ]
        }
    ]
};

const tile2 = {
    store: null,
    id: rootId,
    broadcasts: [],
    preview: {
        type: "Text" as const,
        spans: [
            {
                type: "Text" as const,
                text: "Just a line of "
            },
            {
                type: "Text" as const,
                styles: ["Bold" as const],
                text: "bold, "
            },
            {
                type: "Text" as const,
                styles: ["Italic" as const, "Bold" as const],
                text: "italic-bold, "
            },
            {
                type: "Text" as const,
                styles: ["Struck" as const],
                text: "struck "
            },
            {
                type: "Text" as const,
                styles: ["Mono" as const],
                text: "& mono"
            },
            {
                type: "Text" as const,
                text: " text..."
            },
        ],
    },
    branches: [],
    sections: [
        {
            id: id(0),
            hasMultipleParents: true,
            subsections: [
                {
                    id: id(0),
                    block: {
                        type: "Text" as const,
                        spans: [
                            {
                                type: "Text" as const,
                                text: "Just a line of "
                            },
                            {
                                type: "Text" as const,
                                styles: ["Bold" as const],
                                text: "bold, "
                            },
                            {
                                type: "Text" as const,
                                styles: ["Italic" as const, "Bold" as const],
                                text: "italic-bold, "
                            },
                            {
                                type: "Text" as const,
                                styles: ["Struck" as const],
                                text: "struck "
                            },
                            {
                                type: "Text" as const,
                                styles: ["Mono" as const],
                                text: "& mono"
                            },
                            {
                                type: "Text" as const,
                                text: " text..."
                            },
                        ],
                    },
                    after: [
                        {
                            type: "Sibling" as const,
                            link: {
                                descendant: {
                                    id: id(0),
                                    block: {
                                        type: "Text" as const,
                                        spans: [
                                            {
                                                type: "Text" as const,
                                                text: "Home",
                                            }
                                        ],
                                    }
                                },
                                descent: [
                                    parent({ address: 9, index: 1 }),
                                    parent({ address: 10, index: 0 }),
                                ]
                            }
                        }
                    ],
                }
            ]
        },
        {
            id: id(0),
            hasMultipleParents: false,
            subsections: [
                {
                    id: id(0),
                    block: {
                        type: "Text" as const,
                        spans: [
                            {
                                type: "Text" as const,
                                text: "Here is a link: "
                            },
                            {
                                type: "Link" as const,
                                link: {
                                    descendant: {
                                        id: id(0),
                                        block: {
                                            type: "Text" as const,
                                            spans: [
                                                {
                                                    type: "Text" as const,
                                                    text: "Home",
                                                }
                                            ],
                                        }
                                    },
                                    descent: [
                                        parent({ address: 9, index: 1 }),
                                        parent({ address: 10, index: 0 }),
                                    ]
                                }
                            },
                            {
                                type: "Text" as const,
                                text: " (which should not be editable, because it contains a link)"
                            }
                        ],
                    }
                },
            ]
        },
        {
            id: id(0),
            hasMultipleParents: false,
            subsections: [
                {
                    id: id(0),
                    block: {
                        type: "Text" as const,
                        spans: [
                            {
                                type: "Text" as const,
                                text: "",
                            }
                        ]
                    }
                }
            ]
        }
    ]
}

const tile3 = {
    store: null,
    id: rootId,
    broadcasts: [],
    preview: {
        type: "Text" as const,
        spans: []
    },
    branches: [],
    sections: []
}
