import { ActiveDragState, Callbacks, DragAwareProps, MarkupProps, OnDragLink, OnDragSection, OnDragTile, OnOpenTile } from "./Space";
import { atCaretPosition, Caret, insert, navigate, remove, replace, selectBlock, PersistentTile, TileModel, isExternal } from "../model/tile";
import { Section } from "./Section";
import { DropZone } from "./DropZone";
import { RootLink, SectionLink } from "./Link";
import React, { useCallback } from "react";
import { isEditable, SectionModel } from "../model/section";
import { BlockModel } from "../model/block";
import { keyed } from "../model/keyed";
import { PlaceholderSection } from "./PlaceholderSection";
import { Id } from "../model/lineage";
import { Branches } from "./Branches";
import { Broadcast, BroadcastAction } from "./Broadcast";

export type UpdateCaret = (caret: Caret) => void;

type OnDrop = (sectionIndex: number) => void;

interface Props extends MarkupProps, DragAwareProps {
    readonly isDragSource: boolean;
    readonly isClosable: boolean;
    readonly model: TileModel;
    readonly setTile: (f: (prev: PersistentTile) => PersistentTile) => void;
    readonly fillEmptyTile: (id: Id | null) => void;
    readonly onOpenTile: OnOpenTile;
    readonly onCloseTile: (e: React.MouseEvent) => void;
    readonly onDragLink: OnDragLink;
    readonly onDragSection: OnDragSection;
    readonly onDragTile: OnDragTile;
    readonly onDrop: OnDrop;
    readonly callbacks: Callbacks;
}

export const Tile: React.FC<Props> = ({
    isClosable,
    isDragSource,
    model,
    setTile,
    fillEmptyTile,
    onOpenTile,
    onCloseTile,
    onDrop,
    dragState,
    callbacks,
    ...rest
}) => {
    const removeSection = useCallback((index: number) => {
        setTile(tile => {
            const caret = index > 0 ? { type: "end" as const } : { type: "start" as const };
            const tileWithCursorMoved = navigate(tile, index, undefined, caret);
            return remove(tileWithCursorMoved, index);
        });
    }, [setTile]);

    const addSection = useCallback((index: number) => {
        setTile(tile => {
            const emptySection = {
                id: null,
                hasMultipleParents: false,
                subsections: [
                    {
                        id: null,
                        block: {
                            type: "Text" as const,
                            spans: []
                        }
                    }
                ]
            };
            const tileWithSection = insert(tile, index + 1, emptySection);
            return navigate(tileWithSection, index, undefined, { type: "start" });
        });
    }, [setTile])

    function updatedBlock(tile: PersistentTile, section: number, block: number, content: SectionModel[], offset: number) {
        const prevSection = tile.sections[section];
        const remainingPrevBlocks = prevSection.subsections.slice(block + 1);
        const updatedSection = {
            ...prevSection,
            subsections: [
                ...prevSection.subsections.slice(0, block),
                ...content[0].subsections,
                ...(content.length <= 1 ? remainingPrevBlocks : [])
            ],
            id: null,
        };
        const newSections = content.slice(1).map((s, i) => {
            return ({
                ...s,
                subsections: i === content.length - 2 ?
                    [...s.subsections, ...remainingPrevBlocks] :
                    s.subsections,
                id: null,
            });
        });
        const withUpdatedSection = replace(tile, section, updatedSection);
        const withNewSections = newSections.reduce(({ tile, i }, section) => {
            return { tile: insert(tile, i, section), i: i + 1 };
        }, { tile: withUpdatedSection, i: section + 1 });
        if (content.length > 1 || content[0].subsections.length > 1) {
            const nextBlock = {
                section: content.length > 1 ? section + 1 : section,
                block: content.length <= 1 ? block + 1 : 0,
            };
            const nextBlockSelected = selectBlock(withNewSections.tile, nextBlock);
            return atCaretPosition(nextBlockSelected, { type: "start" });
        } else {
            return atCaretPosition(withNewSections.tile, { type: "start", offset });
        }
    };

    const updateBlockContent = useCallback((section: number, block: number, content: SectionModel[], caretPosition: number) => {
        setTile(tile => updatedBlock(tile, section, block, content, caretPosition));
    }, [setTile]);

    const selectBlockInSection = useCallback((section: number, block: number | undefined) => {
        setTile(t => selectBlock(t, { section, block }));
    }, [setTile]);

    const unselectBlockInSection = useCallback((section: number, block: number | undefined, updated: SectionModel[] | null) => {
        if (updated === null || block === undefined) {
            setTile(t => selectBlock(t, {}));
        } else {
            setTile(t => selectBlock(updatedBlock(t, section, block, updated, 0), {}));
        }
    }, [setTile]);

    const mergeWith = useCallback((section: number, block: number, target: "prev" | "next", content: BlockModel, contentLength: number) => {
        const isPrev = target === "prev";
        const isNext = target === "next";
        setTile(tile => {
            if ((isPrev && block > 0) ||
                (isNext && block < tile.sections[section].subsections.length - 1)) {
                const blocks = tile.sections[section].subsections;
                const neighborIndex = isPrev ? block - 1 : block + 1;
                const neighbor = blocks[neighborIndex];
                const spans = isPrev ?
                    [...neighbor.block.spans, ...content.spans] :
                    [...content.spans, ...neighbor.block.spans];
                const mergedBlock = {
                    type: "Text" as const,
                    styles: neighbor.block.styles,
                    spans
                };
                const updatedSection = {
                    ...tile.sections[section],
                    subsections: [
                        ...blocks.slice(0, isPrev ? block - 1 : block),
                        { id: null, block: mergedBlock },
                        ...blocks.slice(isPrev ? block + 1 : block + 2),
                    ],
                    id: null,
                }
                const withMerged = replace(tile, section, updatedSection);
                const mergedSelected = selectBlock(withMerged, {
                    section,
                    block: isPrev ? block - 1 : block,
                });
                return atCaretPosition(mergedSelected, { type: isPrev ? "end" : "start", offset: contentLength });
            } else if ((isPrev && section > 0 && isEditable(isExternal(tile), tile.sections[section - 1])) ||
                (isNext && section < tile.sections.length - 1 && isEditable(isExternal(tile), tile.sections[section + 1]))) {
                const neighborSectionIndex = isPrev ? section - 1 : section + 1;
                const neighborSection = tile.sections[neighborSectionIndex];
                if (neighborSection.subsections.length === 0) {
                    return atCaretPosition(remove(tile, neighborSectionIndex), { type: isPrev ? "start" : "end", offset: 0 });
                }

                const neighborBlockIndex = isPrev ? neighborSection.subsections.length - 1 : 0;
                const neighborBlocks = neighborSection.subsections;
                const neighborBlock = neighborBlocks[neighborBlockIndex];
                const spans = isPrev ?
                    [...neighborBlock.block.spans, ...content.spans] :
                    [...content.spans, ...neighborBlock.block.spans];
                const mergedBlock = {
                    type: "Text" as const,
                    styles: neighborBlock.block.styles,
                    spans
                };
                const updatedSection = {
                    ...neighborSection,
                    subsections: isPrev ?
                        [
                            ...neighborBlocks.slice(0, -1),
                            { id: null, block: mergedBlock },
                            ...tile.sections[section].subsections.slice(1),
                        ] :
                        [
                            ...tile.sections[section].subsections.slice(0, -1),
                            { id: null, block: mergedBlock },
                            ...neighborBlocks.slice(1),
                        ],
                    id: null,
                }
                const withMerged = replace(tile, neighborSectionIndex, updatedSection);
                const withOldRemoved = remove(withMerged, section);
                const mergedSelected = selectBlock(withOldRemoved, {
                    section: isPrev ? section - 1 : section,
                    block: isPrev ? neighborBlocks.length - 1 : tile.sections[section].subsections.length - 1,
                });
                return atCaretPosition(mergedSelected, { type: isPrev ? "end" : "start", offset: contentLength });
            } else if ((isPrev && section > 0) || (isNext && section < tile.sections.length - 1)) {
                const isContentEmpty = !content.spans.some(s => {
                    return s.type !== "Text" || s.text.length > 0;
                });
                if (isContentEmpty) {
                    const prevSelected = navigate(tile, section, block, { type: isPrev ? "end" : "start" });
                    return remove(prevSelected, section);
                } else {
                    return remove(tile, isPrev ? section - 1 : section + 1);
                }
            } else {
                return tile;
            }
        });
    }, [setTile]);

    const uri = model.store;
    const now = Date.now();
    const hasActiveBroadcast = model.broadcasts.some(b => b.node_id === model.id && !isExternal(model));
    return (
        <div className="tile">
            <header>
                <div className="actions">
                    <RootLink uri={uri} tile={model} sectionIndex={-1} onOpenTile={onOpenTile} {...rest} />
                    {isExternal(model) ?
                        <div className="external-broadcast"></div> :
                        model.id == null || <BroadcastAction id={model.id} alreadyActive={hasActiveBroadcast} startBroadcast={callbacks.startBroadcast} />
                    }
                </div>
                {isClosable && <span className="tile-close" onClick={(e) => onCloseTile(e)}>×</span>}
            </header>
            <div className="broadcasts">
                {model.broadcasts
                    .filter(broadcast => broadcast.expiration === null || broadcast.expiration > now)
                    .map(broadcast => [
                        <Broadcast key={broadcast.broadcast_id} model={broadcast} />
                    ])}
            </div>
            <div className="main">
                {model.branches.length > 0 &&
                    <div className="tile-branches">
                        <Branches
                            uri={uri}
                            links={model.branches}
                            position={"before"}
                            sectionIndex={-1}
                            onOpenTile={onOpenTile}
                            {...rest} />
                    </div>}
                {dragState.isDragActive &&
                    <BetweenSectionsDropZone
                        index={0}
                        isTileDragSource={isDragSource}
                        dragState={dragState}
                        onDrop={onDrop} />}
                {model.sections === undefined ||
                    model.sections.length === 0 ?
                    <PlaceholderSection
                        uri={uri}
                        sectionIndex={0}
                        onOpenTile={onOpenTile}
                        onClick={() => fillEmptyTile(model.id)}
                        {...rest}
                    /> :
                    model.sections.map((s, i) =>
                        [
                            s.subsections.length === 0 ?
                                <article key={`empty-${i}`}>
                                    <section>
                                        <div>
                                            <p>
                                                <strong className="whitespace">∅</strong>
                                            </p>
                                        </div>
                                    </section>
                                    <SectionLink
                                        uri={uri}
                                        section={s}
                                        isHighlighted={s.hasMultipleParents}
                                        isSectionEmpty={true}
                                        sectionIndex={i}
                                        onOpenTile={onOpenTile}
                                        {...rest}
                                    />
                                </article> :
                                <Section
                                    key={keyed(s)}
                                    uri={uri}
                                    section={s}
                                    sectionIndex={i}
                                    isExternal={isExternal(model)}
                                    isSelected={model.activeSection === i}
                                    isLastSection={i === model.sections.length - 1}
                                    activeBlock={model.activeBlock}
                                    caret={model.caret !== undefined && model.activeSection === i ? model.caret : null}
                                    selectBlock={(block) => selectBlockInSection(i, block)}
                                    unselect={(block, updated) => unselectBlockInSection(i, block, updated)}
                                    updateContent={(block, sections, caret) => updateBlockContent(i, block, sections, caret)}
                                    mergeWith={(block, target, content, contentLength) => mergeWith(i, block, target, content, contentLength)}
                                    remove={() => removeSection(i)}
                                    add={() => addSection(i)}
                                    onOpenTile={onOpenTile}
                                    dragState={dragState}
                                    {...rest}
                                />,
                            dragState.isDragActive && <BetweenSectionsDropZone
                                key={`drop-zone-${i + 1}`}
                                index={i + 1}
                                isTileDragSource={isDragSource}
                                dragState={dragState}
                                onDrop={onDrop}
                            />
                        ]
                    )
                }
            </div>
        </div>
    );
}

interface DropZoneProps {
    readonly index: number;
    readonly isTileDragSource: boolean;
    readonly onDrop: OnDrop;
    readonly dragState: ActiveDragState;
}

const BetweenSectionsDropZone: React.FC<DropZoneProps> = ({ index, isTileDragSource, dragState, onDrop }) => {
    const className = isTileDragSource && dragState.sourceSectionIndex === index ?
        "drop-zone before-dragged" : isTileDragSource && dragState.sourceSectionIndex === index - 1 ?
            "drop-zone after-dragged" :
            "drop-zone";
    return (
        <div className="drop-zone-container">
            <DropZone
                className={className}
                classNameIfInsideZone={`${className} drag-active`}
                onDrop={() => onDrop(index)}
            >
                <div></div>
            </DropZone>
        </div>
    );
}
