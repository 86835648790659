import { Branch } from "../model/branch";
import { keyed } from "../model/keyed";
import { LineageLink } from "./Link";
import { LinkableProps } from "./Space";

interface BranchesProps extends LinkableProps {
    readonly links: readonly Branch[] | undefined;
    readonly position: "before" | "after";
}

export const Branches: React.FC<BranchesProps> = ({ links, sectionIndex, position, ...rest }) => {
    if (links === undefined || links.length === 0) {
        return null;
    }
    return (
        <aside className={["sibling", position].join(" ")}>
            {position === "before" && <hr />}
            <nav>
                <ul>
                    {links.map(l =>
                        <li key={keyed(l)}>
                            <LineageLink link={l.link} withAncestors={true} sectionIndex={sectionIndex} position={position} {...rest} />
                        </li>
                    )}
                </ul>
            </nav>
            {position === "after" && <hr />}
        </aside>
    );
}
