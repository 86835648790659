import { useState, useCallback, ReactNode } from "react";

export interface DropZoneProps {
    readonly onDrop: (e: React.DragEvent) => void;
    readonly children: ReactNode;
    readonly classNameIfInsideZone?: string;
    [rest: string]: any;
}

export const DropZone: React.FC<DropZoneProps> = ({ onDrop, classNameIfInsideZone, children, ...rest }) => {
    const [isDragInsideZone, setIsDragInsideZone] = useState(false);

    const onDragInside = useCallback((e: React.DragEvent) => {
        e.preventDefault();
        e.stopPropagation();
        setIsDragInsideZone(true);
    }, []);

    const onDragOutside = useCallback((e: React.DragEvent) => {
        e.preventDefault();
        e.stopPropagation();
        setIsDragInsideZone(false);
    }, []);

    const onDropInside = useCallback((e: React.DragEvent) => {
        e.preventDefault();
        e.stopPropagation();
        setIsDragInsideZone(false);
        onDrop(e);
    }, [onDrop]);

    return (
        <div
            onDragEnter={onDragInside}
            onDragOver={onDragInside}
            onDragLeave={onDragOutside}
            onDragExit={onDragOutside}
            onDragEnd={onDragOutside}
            onDrop={onDropInside}
            {...(isDragInsideZone ? { ...rest, className: classNameIfInsideZone } : rest)}
        >
            {children}
        </div>
    );
}
