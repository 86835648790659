import { createElement, useCallback, useRef, useEffect, useLayoutEffect, useState } from "react";
import { BlockModel } from "../model/block";
import { Span, fold as foldSpanStyles } from "./Span";
import { LinkableProps, MarkupProps } from './Space';
import { Caret } from '../model/tile';
import { keyed } from '../model/keyed';
import { SectionModel } from '../model/section';

interface Props extends LinkableProps, MarkupProps {
    readonly isActive: boolean,
    readonly isEditable: boolean,
    readonly isFirstBlock: boolean;
    readonly caret: Caret | null;
    readonly block: BlockModel;
    readonly select: () => void;
    readonly unselect: (updated: SectionModel[] | null) => void;
    readonly updateContent: (sections: SectionModel[], caret: number) => void;
    readonly mergeWith: (target: "prev" | "next", block: BlockModel, blockLength: number) => void;
}

export const Block: React.FC<Props> = (props) => {
    if (props.block.type === "Text") {
        return <TextBlock {...props} />;
    } else {
        throw new Error(`Invalid block: "${props.block.type}"`);
    }
}

const TextBlock: React.FC<Props> = (props) => {
    const {
        isActive,
        isEditable,
        isFirstBlock,
        block,
        select,
        unselect,
        markupToJson,
        jsonToMarkup,
        updateContent,
        mergeWith,
    } = props;

    const ref = useRef<HTMLElement>(null);

    const [hasChanged, setHasChanged] = useState(false);
    const [{ styles, markup, caret }, setContent] = useState({
        styles: new Set(block.styles),
        markup: isEditable ? jsonToMarkup(block) : null,
        caret: props.caret?.type === "start" ? 0 : -1,
    });

    useLayoutEffect(() => {
        if (ref.current !== null) {
            if (isActive) {
                ref.current.focus();
                if (ref.current.childNodes.length > 0) {
                    const range = document.createRange();
                    const lastChild = Array.from(ref.current.childNodes).pop() as Node;
                    if (caret < 0) {
                        range.setStartAfter(lastChild);
                        range.setEndAfter(lastChild);
                        const sel = window.getSelection();
                        sel?.removeAllRanges();
                        sel?.addRange(range);
                    } else if (ref.current.childNodes.length === 1 &&
                        lastChild.nodeType === Node.TEXT_NODE &&
                        (lastChild.textContent?.length ?? 0) >= caret) {
                        range.setStart(lastChild, caret);
                        range.setEnd(lastChild, caret);
                        const sel = window.getSelection();
                        sel?.removeAllRanges();
                        sel?.addRange(range);
                    }
                }
            } else {
                ref.current.blur();
            }
        }
    }, [isActive, caret]);

    useEffect(() => {
        if (hasChanged) {
            window.onbeforeunload = (event: any) => false;
        } else {
            window.onbeforeunload = null;
        }
    }, [hasChanged])

    const parseContent = useCallback(() => {
        if (ref.current !== null && ref.current.textContent !== null) {
            const sections = splitIntoSections(ref.current);
            return sections.map(s => {
                return {
                    id: null,
                    hasMultipleParents: false,
                    subsections: s.map(b => {
                        return { id: null, block: markupToJson(b) };
                    }),
                };
            });
        }
        return null;
    }, [markupToJson]);

    const onClick = useCallback((e: React.MouseEvent) => {
        select();
    }, [select]);

    const onFocus = useCallback((e: React.FocusEvent) => {
        if (!isActive) {
            e.preventDefault();
            e.stopPropagation();
            select();
        }
    }, [isActive, select]);

    const onBlur = useCallback((e: React.FocusEvent) => {
        if (isActive) {
            e.preventDefault();
            e.stopPropagation();
            unselect(hasChanged ? parseContent() : null);
        }
        // to remove window.onbeforeunload listener:
        setHasChanged(false);
    }, [hasChanged, isActive, unselect, parseContent]);

    const onKeyDown = useCallback((e: React.KeyboardEvent) => {
        if (ref.current === null) return;

        if (e.key === "Escape") {
            e.preventDefault();
            e.stopPropagation();
            unselect(hasChanged ? parseContent() : null);
        } else if (e.shiftKey && e.key === "Enter") {
            e.preventDefault();
            e.stopPropagation();
            const [before, after] = splitTextAtCaret(ref.current);
            const content = [{
                id: null,
                hasMultipleParents: false,
                subsections: [
                    { id: null, block: markupToJson(before) },
                    { id: null, block: markupToJson(after) },
                ]
            }];
            updateContent(content, 0);
        } else if (e.key === "Enter") {
            e.preventDefault();
            e.stopPropagation();
            const [before, after] = splitTextAtCaret(ref.current);
            const content = [{
                id: null,
                hasMultipleParents: false,
                subsections: [{ id: null, block: markupToJson(before) }]
            }, {
                id: null,
                hasMultipleParents: false,
                subsections: [{ id: null, block: markupToJson(after) }]
            }];
            updateContent(content, 0);
        } else if (e.key === "Backspace") {
            const before = splitTextAtCaret(ref.current)[0];
            if (before === "") {
                e.preventDefault();
                e.stopPropagation();
                const sections = parseContent();
                const contentLength = ref.current.textContent?.length ?? 0;
                if (sections !== null && sections.length === 1 && sections[0].subsections.length === 1) {
                    const block = sections[0].subsections[0].block;
                    mergeWith("prev", block, contentLength);
                } else {
                    console.error("Cannot merge multiple blocks / sections with prev content");
                    console.error(sections);
                    e.preventDefault();
                    e.stopPropagation();
                }
            }
        }
    }, [hasChanged, markupToJson, parseContent, mergeWith, updateContent, unselect]);

    const onInput = useCallback((e: React.FormEvent) => {
        setHasChanged(true);
        if (ref.current !== null && ref.current.textContent !== null) {
            const content = parseContent();
            if (content !== null && content.length > 0 && content[0].subsections.length > 0) {
                const block = content[0].subsections[0].block;
                const [before, after] = splitTextAtCaret(ref.current);
                setContent(prev => {
                    const styles = new Set(block.styles);
                    if (!eq(prev.styles, styles)) {
                        return {
                            styles,
                            markup: before + after,
                            caret: before.length,
                        };
                    } else {
                        return prev;
                    }
                });
            }
        }
    }, [parseContent]);

    const listeners = { onClick, onFocus, onBlur, onKeyDown, onInput };
    const isHeading = styles.has("Heading");
    const tag = isFirstBlock && isHeading ? "h1" : isHeading ? "h2" : "p";
    const classNames = isActive ? ["block active"] : ["block"];

    let component;
    if (isActive && isEditable) {
        const escaped = (markup ?? "")
            .replace(/&/g, "&amp;")
            .replace(/</g, "&lt;")
            .replace(/>/g, "&gt;")
            .replace(/"/g, "&quot;")
            .replace(/'/g, "&#039;");
        const attrs = {
            ref,
            contentEditable: "true",
            className: classNames.join(" "),
            dangerouslySetInnerHTML: { __html: escaped },
            ...listeners
        };
        component = createElement(tag, attrs, null);
    } else {
        const spansUnstyled = block.spans.map(s =>
            <Span key={keyed(s)} span={s} {...props} />
        );
        const spans = foldSpanStyles(<>{spansUnstyled}</>, styles);
        component = createElement(tag, { ref, className: classNames.join(" "), ...listeners }, spans);
    }

    if (styles.has("List")) {
        let classes = ["bullet"];
        if (styles.has("Heading")) {
            classes.push(isFirstBlock ? "title" : "heading");
        }
        component = <ul className={classes.join(" ")}><li>{component}</li></ul>;
    }
    if (styles.has("Quote")) {
        component = <blockquote>{component}</blockquote>;
    }
    if (styles.has("Aside")) {
        component = <aside>{component}</aside>;
    }
    return component;
}

function splitTextAtCaret(e: HTMLElement): [string, string] {
    const s = window.getSelection();
    if (s !== null && s.rangeCount > 0 && s.getRangeAt(0) !== undefined) {
        const r = s.getRangeAt(0);
        const before = document.createRange();
        before.setStart(e, 0);
        before.setEnd(r.startContainer, r.startOffset);
        const after = document.createRange();
        after.setStart(r.startContainer, r.startOffset);
        after.setEnd(e, e.childNodes.length);
        return [before.toString(), after.toString()];
    } else {
        return [e.textContent ?? "", ""];
    }
}

function eq<T>(a: Set<T>, b: Set<T>): boolean {
    if (a.size !== b.size) return false;
    for (var x of Array.from(a)) {
        if (!b.has(x)) return false;
    }
    return true;
}

export function splitIntoSections(block: HTMLElement): string[][] {
    let child = block;
    while (child.tagName === "DIV" && child.children.length === 1) {
        child = child.children[0] as HTMLElement;
    }

    const split = splitLines(child, [[[""]]]);
    const last = split.slice(-1)[0];
    if (last.length === 1 && last[0].length === 0) split.pop();

    const sections = split.map(s => {
        if (s.length === 0) {
            return [""];
        } else {
            if (s[s.length - 1].length === 0) s.pop();
            const blocks = s.map(block => block.length === 0 ? "" : block.join(""));
            if (blocks.length > 1 && blocks.slice(-1)[0] === "") {
                blocks.pop();
            }
            return blocks;
        }
    })
    return sections.length === 0 ? [[""]] : sections;
}

function splitLines(e: HTMLElement, sections: string[][][]): string[][][] {
    const isSectionEmpty = (s: string[][]) => {
        return !s.some(b => b.some(l => l !== ""));
    }
    for (let c of Array.from(e.childNodes)) {
        if (c.nodeType === Node.ELEMENT_NODE) {
            const e = c as HTMLElement;
            if (e.tagName === "H1" || e.tagName === "P" || e.tagName === "DIV") {
                if (!isSectionEmpty(sections.slice(-1)[0])) {
                    sections.push([[]]);
                }
                splitLines(e, sections);
                sections.push([[]]);
            } else if (e.tagName === "BR") {
                if (sections.length === 0) sections.push([[]]);
                const section = sections[sections.length - 1];
                section.push([]);
            } else {
                splitLines(e, sections);
            }
        } else if (c.nodeType === Node.TEXT_NODE) {
            if (sections.length === 0) sections.push([[]]);
            const section = sections[sections.length - 1];
            if (section.length === 0) section.push([]);
            let block = section[section.length - 1];
            const lines = c.textContent?.split("\n") ?? [""];
            for (let line of lines.slice(0, -1)) {
                block.push(line);
                block = [];
                section.push(block);
            }
            block.push(lines.pop() as string);
        }
    }
    return sections;
}
