import { Tile } from "./Tile";
import { insert, isExternal, move, needSync, NewTile, PersistentTile, TileModel } from "../model/tile";
import { SpaceModel, spaceToHash, hashToSpace, removeTile, mergeSyncedTile, getTile, refreshTile, addTileNow, replaceTileNow, now } from "../model/space";
import { hashId, Id, Lineage, rootId } from "../model/lineage";
import { useCallback, useEffect, useLayoutEffect, useState } from "react";
import { LinkedSectionModel, SectionModel, SectionWithIdModel } from "../model/section";
import { DropZone } from "./DropZone";
import { JsonToMarkup, MarkupToJson } from "../backend";
import { BlockModel } from "../model/block";
import { awaitSync, Queue, scheduleBroadcast, scheduleSync } from "../queue";

export type OnOpenTile = (e: React.MouseEvent, store: string | null, id: Id, section: number | undefined, block: number | undefined) => void;
export type OnDragLink = (e: React.DragEvent, store: string | null, index: number, link: Lineage) => void;
export type OnDragSection = (e: React.DragEvent, store: string | null, index: number, section: SectionWithIdModel) => void;
export type OnDragTile = (e: React.DragEvent, store: string | null, tile: TileModel) => void;

export interface MarkupProps {
    readonly markupToJson: MarkupToJson;
    readonly jsonToMarkup: JsonToMarkup;
}

export interface LinkableProps {
    readonly uri: string | null;
    readonly sectionIndex: number;
    readonly onOpenTile: OnOpenTile;
    readonly onDragLink: OnDragLink;
    readonly onDragSection: OnDragSection;
    readonly onDragTile: OnDragTile;
}

export type DragState = InactiveDragState | ActiveDragState;

export interface InactiveDragState {
    readonly isDragActive: false;
}

export type ActiveDragState = (DraggedLineageState | DraggedSectionState | DraggedTileState) & SharedActiveDragState;

export interface SharedActiveDragState {
    readonly isDragActive: true;
    readonly sourceStore: string | null;
    readonly sourceTileKey: number;
    readonly sourceSectionIndex: number;
}

export interface DraggedLineageState {
    readonly type: "lineage";
    readonly link: Lineage;
}

export interface DraggedSectionState {
    readonly type: "section";
    readonly section: SectionWithIdModel;
}

export interface DraggedTileState {
    readonly type: "tile";
    readonly id: Id;
    readonly preview: BlockModel;
}

function assertNever(dragState: never): never {
    throw new Error(`Invalid dragState: ${dragState}`);
}

export interface DragAwareProps {
    readonly dragState: DragState;
}

export interface Callbacks {
    startBroadcast: (id: Id, onComplete: () => void) => void;
}

export const Space: React.FC<{ queue: Queue }> = ({ queue }) => {
    const model = hashToSpace(queue, window.location.hash);
    const backend = queue.backend;
    const [space, setSpace] = useState(model);
    const [dragState, setDragState] = useState<DragState>({ isDragActive: false });

    useEffect(() => {
        window.onhashchange = () => {
            setSpace(prev => {
                if (spaceToHash(prev) === window.location.hash) {
                    return prev;
                } else {
                    return hashToSpace(queue, window.location.hash);
                }
            });
        };
    }, [queue]);

    useEffect(() => {
        if (spaceToHash(space) !== window.location.hash) {
            window.location.hash = spaceToHash(space);
        }

        for (let k of space.keys) {
            const synced = space.sync.get(k);
            const current = space.tiles.get(k);
            if (current === undefined || current === synced) {
                continue;
            } else if (current.id !== null && current.sections === undefined) {
                console.log("Loading tile...");
                const timestamp = now();
                backend.refresh(current.store, current.id).then(tile => {
                    if (isExternal(current) && current.store !== null && current.id !== null) {
                        backend.fetch(current.store, current.id).then(tile => {
                            setSpace(space => {
                                const refreshed = { ...current, ...tile };
                                return refreshTile(space, k, refreshed, timestamp);
                            });
                        })
                    }
                    setSpace(space => {
                        const refreshed = { ...current, ...tile };
                        return refreshTile(space, k, refreshed, timestamp);
                    });
                }).catch(error => {
                    console.error(error);
                });
            } else if (current.sections !== undefined &&
                !dragState.isDragActive &&
                (synced?.id === undefined || needSync(synced, current))) {
                console.log("Syncing tile...");
                const mapSections = (section: SectionModel | LinkedSectionModel) => {
                    if (section.linked !== undefined) {
                        return {
                            type: "Linked" as const,
                            id: section.linked,
                        };
                    } else if (section.id === null) {
                        return {
                            type: "Edited" as const,
                            blocks: section.subsections.map(s => ({
                                type: "Text" as const,
                                markup: backend.jsonToMarkup(s.block)
                            })),
                        };
                    } else {
                        return {
                            type: "Existing" as const,
                            id: section.id
                        };
                    }
                }
                const timestamp = now();
                const sections = current.sections.map(s => mapSections(s));
                scheduleSync(space.queue, current.id, sections);
                awaitSync(space.queue, current.store, current.id, async synced => {
                    const tiles: TileModel[] = await Promise.all(space.keys.map(async tileKey => {
                        if (tileKey === k) {
                            return synced;
                        } else {
                            const tile: TileModel = getTile(space, tileKey);
                            if (tile.id === null) {
                                return tile;
                            } else {
                                const refreshed: PersistentTile = await backend.refresh(tile.store, tile.id);
                                return { ...tile, ...refreshed };
                            }
                        }
                    }));
                    const tilesByKey = new Map(space.keys.map((k, i) => [k, tiles[i]]));
                    setSpace(space => {
                        let merged = space;
                        for (var tileKey of space.keys) {
                            const tile = tilesByKey.get(tileKey);
                            if (tile !== undefined && tile.id !== null && tile.sections !== undefined) {
                                merged = mergeSyncedTile(merged, tileKey, tile, timestamp);
                            }
                        }
                        return merged;
                    });
                });
            }
        }
    }, [dragState, space, backend]);

    const onOpenTile = useCallback((e: React.MouseEvent, tileKey: number, store: string | null, id: Id, activeSection?: number, activeBlock?: number) => {
        e.preventDefault();
        e.stopPropagation();
        setSpace(prev => {
            const asNewTile = e.shiftKey && hasRoom(prev);
            const tile: NewTile = { store, id, broadcasts: [], activeSection, activeBlock, sections: undefined, branches: [] };
            if (asNewTile) {
                return addTileNow(prev, tile);
            } else {
                return replaceTileNow(prev, tileKey, tile);
            }
        });
    }, []);

    const onOpenNew = useCallback((e: React.MouseEvent) => {
        e.preventDefault();
        e.stopPropagation();
        setSpace(prev => {
            const asNewTile = hasRoom(prev);
            const tile = { store: null, id: null, broadcasts: [], fragment: undefined, sections: undefined, branches: [] };
            return asNewTile ? addTileNow(prev, tile) : prev;
        });
    }, []);

    const onCloseTile = useCallback((e: React.MouseEvent, tileKey: number) => {
        e.preventDefault();
        e.stopPropagation();
        setSpace(prev => removeTile(prev, tileKey));
    }, []);

    const onDragLink = useCallback((e: React.DragEvent, tileKey: number, sourceStore: string | null, index: number, link: Lineage) => {
        setDragState({
            type: "lineage" as const,
            sourceStore,
            link,
            isDragActive: true,
            sourceTileKey: tileKey,
            sourceSectionIndex: index,
        });
        e.dataTransfer.effectAllowed = "move";
        e.dataTransfer.dropEffect = "move";
    }, []);

    const onDragSection = useCallback((e: React.DragEvent, tileKey: number, sourceStore: string | null, index: number, section: SectionWithIdModel) => {
        setDragState({
            type: "section" as const,
            sourceStore,
            section,
            isDragActive: true,
            sourceTileKey: tileKey,
            sourceSectionIndex: index,
        });
        e.dataTransfer.effectAllowed = "move";
        e.dataTransfer.dropEffect = "move";
    }, []);

    const onDragTile = useCallback((e: React.DragEvent, tileKey: number, sourceStore: string | null, tile: TileModel) => {
        if (tile.id !== null && tile.preview) {
            setDragState({
                type: "tile" as const,
                sourceStore,
                id: tile.id,
                preview: tile.preview,
                isDragActive: true,
                sourceTileKey: tileKey,
                sourceSectionIndex: -2,
            });
            e.dataTransfer.effectAllowed = "move";
            e.dataTransfer.dropEffect = "move";
        } else {
            e.preventDefault();
            e.stopPropagation();
        }
    }, []);

    const onDropBetweenSections = useCallback((tileKey: number, sectionIndex: number) => {
        if (dragState.isDragActive) {
            setSpace(prev => {
                const t = getTile(prev, tileKey);
                if (dragState.type === "lineage") {
                    const dragged = {
                        id: dragState.link.descendant.id,
                        hasMultipleParents: true,
                        subsections: [
                            {
                                id: dragState.link.descendant.id,
                                block: dragState.link.descendant.block,
                            }
                        ]
                    };
                    if (t.sections !== undefined) {
                        const tile = insert(t, sectionIndex, dragged);
                        return replaceTileNow(prev, tileKey, tile);
                    } else {
                        return prev;
                    }
                } else if (dragState.type === "section") {
                    const dragged = dragState.section;
                    const isMove = tileKey === dragState.sourceTileKey;
                    if (t.sections !== undefined) {
                        const tile = isMove ? move(t, t.sections.indexOf(dragged), sectionIndex) : insert(t, sectionIndex, dragged);
                        return replaceTileNow(prev, tileKey, tile);
                    } else {
                        return prev;
                    }
                } else if (dragState.type === "tile") {
                    const id = dragState.id;
                    const preview = dragState.preview;
                    if (t.sections !== undefined) {
                        const tileAsLinkSection = {
                            id: null,
                            linked: id,
                            hasMultipleParents: true as const,
                            subsections: [
                                {
                                    id: null,
                                    block: {
                                        type: "Text" as const,
                                        spans: [
                                            {
                                                type: "Link" as const,
                                                link: {
                                                    descendant: {
                                                        id: id,
                                                        block: preview,
                                                    },
                                                    descent: [],
                                                },
                                            }
                                        ]
                                    }
                                }
                            ]
                        };
                        const inserted = insert(t, sectionIndex, tileAsLinkSection);
                        return replaceTileNow(prev, tileKey, inserted);
                    } else {
                        return prev;
                    }
                } else {
                    assertNever(dragState);
                }
            })
        }
        setDragState({ isDragActive: false });
    }, [dragState]);

    const onDropAsNewTile = useCallback(() => {
        if (dragState.isDragActive) {
            setSpace(prev => {
                const store = dragState.sourceStore;
                if (dragState.type === "lineage") {
                    const id = dragState.link.descendant.id;
                    const tile = { store, id, broadcasts: [], sections: undefined, branches: [] };
                    return addTileNow(prev, tile);
                } else if (dragState.type === "section") {
                    const id = dragState.section.id;
                    const tile = { store, id, broadcasts: [], sections: undefined, branches: [] };
                    return addTileNow(prev, tile);
                } else if (dragState.type === "tile") {
                    const tile = { store, id: dragState.id, broadcasts: [], sections: undefined, branches: [] };
                    return addTileNow(prev, tile);
                } else {
                    assertNever(dragState);
                }
            });
        }
        setDragState({ isDragActive: false });
    }, [dragState])

    const onIgnoreDrop = useCallback(() => {
        setDragState({ isDragActive: false });
    }, []);

    const setTile = useCallback((tileKey: number, mapTile: (prev: PersistentTile) => PersistentTile) => {
        setSpace(prev => {
            const t = getTile(prev, tileKey);
            if (t.sections === undefined) {
                console.error("Trying to update non-syncable tile:");
                console.error(t);
                return prev;
            } else {
                return replaceTileNow(prev, tileKey, mapTile(t));
            }
        });
    }, []);

    const fillEmptyTile = useCallback((tileKey: number, id: Id | null) => {
        const placeholder: PersistentTile = {
            store: null,
            id,
            preview: {
                type: "Text" as const,
                spans: [
                    {
                        type: "Text" as const,
                        text: "",
                    }
                ]
            },
            broadcasts: [],
            branches: [],
            sections: [
                {
                    id: null,
                    hasMultipleParents: false,
                    subsections: [
                        {
                            id: null,
                            block: {
                                type: "Text" as const,
                                spans: [
                                    {
                                        type: "Text" as const,
                                        text: "",
                                    }
                                ]
                            }
                        }
                    ]
                }
            ],
            activeSection: 0,
            activeBlock: 0,
        };
        setSpace(prev => replaceTileNow(prev, tileKey, placeholder));
    }, []);

    const [hasRoomForTile, setHasRoomForTile] = useState(true);
    useLayoutEffect(() => {
        setHasRoomForTile(hasRoom(space));
        window.addEventListener('resize', () => {
            setHasRoomForTile(hasRoom(space));
        });
    }, [space]);

    const startBroadcast = useCallback((tileKey: number, id: Id, onComplete: () => void) => {
        scheduleBroadcast(space.queue, id);
        awaitSync(space.queue, null, id, async synced => {
            console.log(synced.broadcasts);
            setTile(tileKey, prev => {
                return {...prev, broadcasts: synced.broadcasts}
            });
            onComplete();
        });
    }, [space, setTile]);

    return (
        <div className="tiles" onDragEnd={onIgnoreDrop} onDrop={onIgnoreDrop}>
            {space.keys.map((key, i) =>
                [
                    <Tile
                        key={`tile-${key}`}
                        {...backend}
                        isDragSource={dragState.isDragActive && dragState.sourceTileKey === key}
                        isClosable={space.keys.length > 1}
                        model={getTile(space, key)}
                        setTile={(prev) => setTile(key, prev)}
                        fillEmptyTile={(id) => fillEmptyTile(key, id)}
                        onOpenTile={(e, id, section, block) => onOpenTile(e, key, id, section, block)}
                        onCloseTile={(e) => onCloseTile(e, key)}
                        onDragLink={(e, uri, index, link) => onDragLink(e, key, uri, index, link)}
                        onDragSection={(e, uri, index, section) => onDragSection(e, key, uri, index, section)}
                        onDragTile={(e, uri, tile) => onDragTile(e, key, uri, tile)}
                        onDrop={(sectionIndex) => onDropBetweenSections(key, sectionIndex)}
                        dragState={dragState}
                        callbacks={{
                            startBroadcast: (id, onComplete) => startBroadcast(key, id, onComplete)
                        }}
                    />,
                    <div
                        key={`separator-${key}`}
                        className={i === space.keys.length - 1 ? "gutter-separator" : "tile-separator"}
                    />
                ]
            )}
            {hasRoomForTile && <DropZone
                className={"space-gutter"}
                classNameIfInsideZone={"space-gutter dragged-over-gutter"}
                onDrop={onDropAsNewTile}
            >
                <a className="assemblage-logo"
                    href={`#${hashId(null, rootId)}`}
                    onClick={(e: React.MouseEvent) => onOpenNew(e)}
                >
                    <span>+</span>
                </a>
            </DropZone>}
        </div >
    );
}

function hasRoom({ keys }: SpaceModel): boolean {
    const tiles = keys.length;
    const tileWidth = window.innerWidth / (tiles + 1);
    return tileWidth > 300 || tiles <= 1;
}
