import { blockName } from "./block";
import { Keyed } from "./keyed";
import { Lineage } from "./lineage";

export type SpanModel = (TextSpanModel | LinkSpanModel) & Keyed;

export interface TextSpanModel {
    readonly type: "Text";
    readonly styles?: readonly Style[];
    readonly text: string;
}

export interface LinkSpanModel {
    readonly type: "Link";
    readonly styles?: readonly Style[];
    readonly link: Lineage;
}

export type Style = "Bold" | "Italic" | "Struck" | "Mono" | "Marked";

export function spanName(span: SpanModel): string {
    if (span.type === "Text") {
        return span.text;
    } else if (span.type === "Link") {
        return blockName(span.link.descendant.block);
    } else {
        assertNever(span);
    }
}

export function isEmpty(span: SpanModel): boolean {
    if (span.type === "Text") {
        return span.text.trim() === "";
    } else if (span.type === "Link") {
        return false;
    } else {
        assertNever(span);
    }
}

function assertNever(span: never): never {
    throw new Error(`Invalid span: ${span}`);
}
